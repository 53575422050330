import { Fragment } from "react";
import React from "react";

export const JAPANESE = {
    LANG_CODE: "ja",
    COUNTRY_CODE: "英語",
    CURRENCY: {
        symbol: "€",
        name: "ユーロ",
        id: "0",
        minPaymentAmount: "0.01",
        maxPaymentAmount: "15000",
        billingLabel: "ユーロ",
        traderKey: ""
    },
    FOOTER: {
        copyright: "Eligma Ltd.",
        version: "バージョン"
    },
    LOGIN: {
        username: "ユーザー名",
        password: "パスワード",
        usernameEmptyError: "ユーザー名を入力",
        passwordEmptyError: "パスワードを入力",
        login: "ログイン",
        notAuthorized: "許可されていません",
        hidePassword: "隠す",
        showPassword: "表示",
        invalidCredentials: "ユーザー名またはパスワードが違います"
    },
    HEADER: {
        help: "ヘルプ",
        callAgent: "エージェントに電話する",
        agentNumber: ""
    },
    SIDEBAR: {
        logout: "ログアウト",
        newPayment: "新規支払い",
        archive: "アーカイブ",
        billing: "請求",
        faq: "よくある質問"
    },
    ARCHIVE: {
        overviewTitle: "受信した取引一覧",
        refreshLabel: "更新",
        exportLabel: "エクスポート",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "時間",
            lastUpdatedDate: "日付",
            lastUpdated: <span>時間<span className="mobile-hidden"> 日付</span> </span>,
            amount: "値",
            combinedAmount: <span>値<span className="mobile-hidden"> </span> </span>,
            cryptoCurrency: "通貨",
            cryptoAmount: "暗号化通貨の値",
            print: "印刷",
            status: "状態",
            referenceNumber: "参照",
            revert: "取り消す"
        },
        export: {
            startDate: "開始日",
            endDate: "終了日",
            error: "日付範囲を選択",
            exportTitle: "支払いをエクスポート",
            lastMonth: "先月",
            showResults: "結果を表示",
            successfulPayments: "完了した支払い"
        },
        printLabel: "印刷",
        print: {
            amountLabel: "通貨金額",
            transactionInfoLabel: "取引情報",
            dateTimeLabel: "日時"
        },
        emptyList: "アーカイブに支払いデータがありません"
    },
    PAYMENT: {
        stepLabel: "手順",
        fromLabel: "の",
        createPayment: "支払いを作成する",
        paymentReady: "支払い準備完了",
        paymentFinished: "支払いを完了する",
        amount: "お支払金額",
        amountErrorLow: "入力された金額が少なすぎます",
        amountErrorHigh: "入力した金額が高すぎます",
        referenceNumberLabel: "参照番号",
        createPaymentButton: "支払いを続ける",
        cancelPaymentButton: "支払いをキャンセルする",
        qrCodeReady: "QRコードをスキャンする準備ができました",
        resetPaymentButton: "新規支払い",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "開始済み",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "支払い中",
            CRYPTO_PAYMENT_STATUS_PAID: "成功",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "処理中",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "期限切れ",
            CRYPTO_PAYMENT_STATUS_FAILED: "失敗",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "無効",
            CRYPTO_PAYMENT_STATUS_REVERTED: "返金済み",
            CRYPTO_PAYMENT_STATUS_CANCELED: "キャンセル済み",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "金額不足"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "QRコードをスキャンする準備ができました",
            CRYPTO_PAYMENT_STATUS_PAID: "支払い完了",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "支払い期限が切れました",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "支払い金額が低すぎます。\nサポートが必要な場合はGoCryptoまで下記へお問い合わせください: support@gocrypto.com"
        },
        currencyNotice: "ユーロの値は、Eligma Ltd.がDD.MM.YYYYで提供する為替レートに基づいています。"
    },
    EXPORT: {
        exportTransactionsLabel: "取引のエクスポート",
        timeRangeLabel: "期間",
        fileTypeLabel: "ファイルの種類",
        exportLabel: "エクスポート",
        lastMonth: "",
        showResults: ""
    },
    REPORT: {
        dailyReport: {
            title: "請求",
            exportLabel: "エクスポート",
            print: "印刷",
            noTransactions: "取引なし",
            currencyLabel: "値--",
            totalLabel: "合計--",
            back: "戻る",
            amountLabel: "金額",
            currencyInLabel: "- 通貨："
        }
    },
    ERROR: {
        unknownCause: "予期しないエラーが発生しました",
        reload: "最初にキャッシュをリロード/クリアしてみてください",
        report: "問題が解決しない場合は、ご報告ください"
    },
    GOCRYPTO: "",
    TOOLTIPS: {
        PAYMENT: {
            mobile: {
                0: "キーボードを使用して、支払い金額を入力してください。",
                1: "[続行]をクリックして支払いに進みます。これにより、QRコードをスキャンするための画面が開きます。"
            },
            tablet: {
                0: "キーボードを使用して、支払い金額を入力してください。",
                1: "[続行]をクリックして支払いに進みます。これにより、QRコードをスキャンするための画面が開きます。"
            },
            desktop: {
                0: "キーボードを使用して、支払い金額を入力してください。",
                1: "[続行]をクリックして支払いに進みます。これにより、QRコードをスキャンするための画面が開きます。"
            }
        },
        ARCHIVE: {
            mobile: {
                0: "時間と状態に応じて支払いを表示します。",
                1: "PDFまたはCSV形式で、時間と結果に応じて支払いデータをエクスポートします。"
            },
            tablet: {
                0: "時間と状態に応じて支払いを表示します。",
                1: "PDFまたはCSV形式で、時間と結果に応じて支払いデータをエクスポートします。"
            },
            desktop: {
                0: "時間と状態に応じて支払いを表示します。",
                1: "PDFまたはCSV形式で、時間と結果に応じて支払いデータをエクスポートします。"
            }
        },
        next: "次へ"
    },
    FAQ: {
        title: "ウェブPOS - よくある質問",
        QA: [
            {
                question: "GoCryptoの支払い画面を作成するにはどうすればよいですか？",
                answer: <Fragment><p>メインメニューで「新規支払い」を選択し、キーボードまたはタッチスクリーンを使用して指定の入力欄に金額を入力します。参照番号は任意に追加できます。</p><p>「支払いの作成」をクリックすると、QRコードが生成されます。QRコード、または、レジのステッカー（画面に簡単にアクセスできない場合のみ）をスキャンするよう購入者に依頼してください。</p><p>購入者が手順を完了すると、緑色の矢印が表示され、ステータスが「支払い完了」となります。これは、支払いの受領を意味し、請求書を発行することができます。</p><p>会計上、必要に応じて、「アーカイブ」からレシートを印刷できます。</p></Fragment>
            },
            {
                question: "支払い期限が切れたようです。これはどういうことでしょうか？",
                answer: <Fragment><p>支払い期限タイマー付きのQRコードが表示されます。この時点から、ユーザーは5分以内に支払いを完了する必要があります。これ以上時間が経過した場合、処理の期限が切れてしまうため、もう一度支払い画面を作成する必要があります。</p></Fragment>
            },
            {
                question: "間違った支払い金額を入力し、QRコードが生成されました。どうすればいいですか？",
                answer: <Fragment><p>ご心配いりません。「支払いをキャンセル」ボタンをクリックして、手順をもう一度繰り返してください。</p></Fragment>
            },
            {
                question: "どこから支払い履歴を確認し、エクスポートできますか？",
                answer: <Fragment><p>メインメニューの「アーカイブ」をクリックして、全ての支払い履歴をスクロールしながら確認できます。支払いステータスと日付から、表示される結果をフィルタリング可能です。会計の用途に合わせて、アーカイブをCSVまたはPDF形式でエクスポートすることもできます。</p></Fragment>
            },
            {
                question: "会計から日別の明細書を作成できますか？",
                answer: <Fragment><p>もちろんです。メインメニューの「請求」をクリックすると、各々の通貨での支払いが掲載された日別の明細書を印刷できます。</p></Fragment>
            },
            {
                question: "どの暗号化通貨に対応していますか？",
                answer: <Fragment><p>ビットコイン、ビットコインキャッシュ、イーサリアム、GoCトークン、Tezos (XTZ), ライトコイン (LTC)、Viberate token (VIB)での支払が可能ですが、心配は不要です。ユーザーがQRコードをスキャンすると、ユーザーがアプリ内で支払い通貨を選択するので、店舗側のプロセスはすべて同じです。</p><p>暗号化通貨に加えて、一部の地域でのGoCryptoは事前預金のユーロでの支払いにも対応しています。</p></Fragment>
            },
            {
                question: "購入者はどのウォレットで支払えますか？",
                answer: <Fragment><p>購入者はEllyまたはBitcoin.comのアプリを使用して支払えます。ユーザーがどのウォレットを選択しても、店舗側の手順はまったく同じです。</p></Fragment>
            },
            {
                question: "通常の通貨での支払いは可能ですか？",
                answer: <Fragment><p>はい、一部の地域での暗号化通貨に加えて、GoCryptoは事前預金したユーロでの支払いにも対応しています。</p></Fragment>
            },
            {
                question: "購入者が払い戻しを求めています。",
                answer: <Fragment><p>正常に支払いが完了した後、払い戻しは店舗側より行われる必要があり、GoCryptoシステムを通しての払い戻しは出来ません。</p></Fragment>
            },
            {
                question: "支払いの作成またはGoCrypto POSの使用で問題が発生しました。",
                answer: <Fragment><p>エージェントまでお電話ください。電話番号 <a href="mailto:support@gocrypto.com"> もしくは、support@gocrypto.com</a>までメールをお送りください。</p></Fragment>
            }
        ]
    },
    XXXXXXXXXX: "ユーロの値は、Eligma Ltd.がDD.MM.YYYYで提供する為替レートに基づいています。"
}