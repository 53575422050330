import React, {Fragment} from 'react';
import css from "./header.module.css";
import * as classnames from "classnames";
import {Container} from "reactstrap";
import ThemedButton from "../../components/ThemeButton/ThemeButton";
import lightBulbIcon from "../../assets/Bulb-icon.svg";
import phoneIcon from "../../assets/phoneIcon.svg";
import shopIcon from "../../assets/Shop-profile-logo.svg";
import {getSafeDeep} from "../../utils/state";
import {bindActionCreators} from "redux";
import {replace} from "connected-react-router";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {routes} from "../../utils/routes";
import elipayLogo from "../../assets/Elipay_logo.svg";
import t, {isGoCrypto} from "../../translation/translation";
import goCryptoLogo from "../../assets/primaryLogo.svg";
import {NavLink} from "react-router-dom";


// Set prop types
type HeaderProps = {};

class Header extends React.Component<HeaderProps> {

    renderHeader() {
        const goCrypto = isGoCrypto();
        const userData = this.props.user;
        return  <div className={css.header}>
            <Container>
                <div className={classnames(css.dFlexCenter, css.between)}>
                    <div className={css.dFlexCenter}>
                        <ThemedButton link={true} linkTo={routes.help.path} className={css.helpButton} outline>
                            <img className={css.lightbulb} src={lightBulbIcon} />
                            {t('HEADER.help')}
                        </ThemedButton>
                        <Fragment>
                            <div className={css.text}>{t('HEADER.callAgent')}</div>
                            <div className={css.dFlex}>
                                <img className={css.phoneIcon} src={phoneIcon} />
                                <div className={css.phoneNumber}>
                                    {t('HEADER.agentNumber')}

                                </div>
                            </div>
                        </Fragment>
                    </div>
                    <div className={css.dFlexCenter}>
                        {userData && <Fragment>
                            <div className={css.text}>
                                {getSafeDeep(userData, "source.0.full_name", "Store")}
                            </div>
                            <div className={css.shopWrapper}>
                                <img src={getSafeDeep(userData, "source.0.logo_url", shopIcon)} />
                            </div>
                        </Fragment>}
                    </div>
                </div>
            </Container>
        </div>
    }

    renderMobileHeader() {
        const goCrypto = isGoCrypto();
        const userData = this.props.user;
        const logo = goCrypto ? goCryptoLogo : elipayLogo;
        return <div className={css.mobileHeader}>
            <div className={classnames(css.dFlexCenter, css.between)}>
                <NavLink to={routes.payment.path}><img className={classnames(css.logo, {[css.gocrypto]: goCrypto})} src={logo} /></NavLink>
                <div>
                    <div className={css.phoneNumber}>
                        <img className={css.phoneIcon} src={phoneIcon} />
                        <a href={`tel:${t('HEADER.agentNumber').replace(" ", "")}`}>{t('HEADER.callAgent')}</a>
                    </div>

                    <div className={css.mobileShop}>
                        {getSafeDeep(userData, "source.0.full_name", "Store")}
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const userData = this.props.user;
        return (
            this.renderMobileHeader()
        );
    };

}

// Set default props
Header.defaultProps = {};
function mapState(state) {
    return {
        user: state.user.data
    }
}

function mapActions(dispatch){
    return bindActionCreators({replace}, dispatch);
}


export default withRouter(connect(mapState, mapActions)(Header));
