import React, { Fragment } from "react";
import { ENGLISH } from "./en";

export const SWITZERLAND = {
    ...ENGLISH,
    LANG_CODE: "en",
    COUNTRY_CODE: "ch",
    CURRENCY: {
        symbol: 'CHF',
        name: 'CHF',
        id: 13,
        minPaymentAmount: 0.01,
        maxPaymentAmount: 16488.0,
        traderKey: "CHF/EUR.traders.base_fiat_trader",
    },
    FOOTER: {
        copyright: "Eligma Ltd.",
        version: "version"
    },
    PAYMENT: {
        stepLabel: "step",
        fromLabel: "of",
        createPayment: "Create payment",
        paymentReady: "Ready for payment",
        paymentFinished: "Finish payment",
        amount: "Payment amount",
        amountErrorLow: "The entered amount is too low",
        amountErrorHigh: "The entered amount is too high",
        referenceNumberLabel: "Reference number",
        createPaymentButton: "Continue to payment",
        cancelPaymentButton: "cancel payment",
        qrCodeReady: "QR code ready for scanning",
        resetPaymentButton: "New payment",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "OPENED",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "IN PAYMENT",
            CRYPTO_PAYMENT_STATUS_PAID: "SUCCESSFUL",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "PROCESSING",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "EXPIRED",
            CRYPTO_PAYMENT_STATUS_FAILED: "UNSUCCESSFUL",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "NOT VALID",
            CRYPTO_PAYMENT_STATUS_REVERTED: "REVERTED",
            CRYPTO_PAYMENT_STATUS_CANCELED: "CANCELED",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "INSUFFICIENT"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "QR code ready for scanning",
            CRYPTO_PAYMENT_STATUS_PAID: "Payment successful",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Time for payment has expired",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "Payment too low.\nFor assistance, please contact GoCrypto at: support@gocrypto.com"
        },
        currencyNotice: "The euro value is based on the exchange rate provided by the Eligma ltd. on"
    },

    ARCHIVE: {
        overviewTitle: "List of received transactions",
        refreshLabel: "REFRESH",
        exportLabel: "EXPORT",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "TIME",
            lastUpdatedDate: "DATE",
            lastUpdated: <span>TIME <span className="mobile-hidden"> DATE</span></span>,
            amount: "VALUE",
            combinedAmount: <span>VALUE <span className="mobile-hidden"> </span></span>,
            cryptoCurrency: "CURRENCY",
            cryptoAmount: "VALUE IN CRYPTO",
            print: "PRINT",
            status: "STATUS",
            referenceNumber: "REFERENCE"
        },
        export: {
            startDate: "Start date",
            endDate: "End date",
            error: "Select a date range",
            exportTitle: "Export payments",
            lastMonth: "Last month",
            showResults: "SHOW RESULTS",
            successfulPayments: "Successful payments"
        },
        printLabel: "Print",
        print: {
            amountLabel: "Currency amount",
            transactionInfoLabel: "Transaction information",
            dateTimeLabel: "Date and time",
        },
        emptyList: "There are no payments in archive"
    },
};
