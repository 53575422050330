import { createStore,compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import promise from "redux-promise-middleware";
import reducers from "./redux";

export const currentHistory = createBrowserHistory();

const middleware = applyMiddleware(routerMiddleware(currentHistory), promise, thunk);
export default function configureStore(preloadedState) {
    const store = createStore(
        reducers(currentHistory),
        preloadedState,
        compose(middleware)
    );
    return store;
}

