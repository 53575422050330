import React, { Fragment } from "react";

export const ENGLISH = {
    LANG_CODE: "en",
    COUNTRY_CODE: "en",
    CURRENCY: {
        symbol: '€',
        name: 'EUR',
        id: "0",
        minPaymentAmount: "0.01",
        maxPaymentAmount: "15000",
        billingLabel: "EUR",
        traderKey: ""
    },
    FOOTER: {
        copyright: "Eligma Ltd.",
        version: "version"
    },
    LOGIN: {
        username: "Username",
        password: "Password",
        usernameEmptyError: "Enter username",
        passwordEmptyError: "Enter password",
        login: "LOG IN",
        notAuthorized: "Not authorized",
        hidePassword: "Hide",
        showPassword: "Show",
        invalidCredentials: "Incorrect username or password"
    },
    HEADER: {
        help: "HELP",
        callAgent: "Call agent",
        agentNumber: ""
    },
    SIDEBAR: {
        logout: "Log out",
        newPayment: 'New payment',
        archive: "Archive",
        billing: "Billing",
        faq: "FAQ"
    },
    ARCHIVE: {
        overviewTitle: "List of received transactions",
        refreshLabel: "REFRESH",
        exportLabel: "EXPORT",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "TIME",
            lastUpdatedDate: "DATE",
            lastUpdated: <span>TIME <span className="mobile-hidden"> DATE</span></span>,
            amount: "VALUE",
            combinedAmount: <span>VALUE <span className="mobile-hidden"> </span></span>,
            cryptoCurrency: "CURRENCY",
            cryptoAmount: "VALUE IN CRYPTO",
            print: "PRINT",
            status: "STATUS",
            referenceNumber: "REFERENCE",
            revert: "Revert"
        },
        export: {
            startDate: "Start date",
            endDate: "End date",
            error: "Select a date range",
            exportTitle: "Export payments",
            lastMonth: "Last month",
            showResults: "SHOW RESULTS",
            successfulPayments: "Successful payments"
        },
        printLabel: "Print",
        print: {
            amountLabel: "Currency amount",
            transactionInfoLabel: "Transaction information",
            dateTimeLabel: "Date and time",
        },
        emptyList: "There are no payments in archives"
    },
    PAYMENT: {
        stepLabel: "step",
        fromLabel: "of",
        createPayment: "Create payment",
        paymentReady: "Ready for payment",
        paymentFinished: "Finish payment",
        amount: "Payment amount",
        amountErrorLow: "The entered amount is too low",
        amountErrorHigh: "The entered amount is too high",
        referenceNumberLabel: "Reference number",
        createPaymentButton: "Continue to payment",
        cancelPaymentButton: "cancel payment",
        qrCodeReady: "QR code ready for scanning",
        resetPaymentButton: "New payment",
        unknown: "Payment cannot be created. Please contact our support.",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "OPEN",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "IN PAYMENT",
            CRYPTO_PAYMENT_STATUS_PAID: "SUCCESSFUL",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "PROCESSING",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "EXPIRED",
            CRYPTO_PAYMENT_STATUS_FAILED: "FAILED",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "INVALID",
            CRYPTO_PAYMENT_STATUS_REVERTED: "REFUNDED",
            CRYPTO_PAYMENT_STATUS_CANCELED: "CANCELLED",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "INSUFFICIENT"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "QR code ready for scanning",
            CRYPTO_PAYMENT_STATUS_PAID: "Payment successful",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Time for payment has expired",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "Payment too low.\nFor assistance, please contact GoCrypto at: support@gocrypto.com"
        },
        currencyNotice: ""
    },
    EXPORT: {
        exportTransactionsLabel: "EXPORT TRANSACTIONS",
        timeRangeLabel: "Time period",
        fileTypeLabel: "File type",
        exportLabel: "EXPORT",
        lastMonth: "",
        showResults: ""
    },
    REPORT: {
        dailyReport: {
            title: "Billing",
            exportLabel: "EXPORT",
            print: "PRINT",
            noTransactions: "No transactions",
            currencyLabel: "Value in",
            totalLabel: "Total in",
            back: "BACK",
            amountLabel: "AMOUNT",
            currencyInLabel: "in"
        }
    },
    ERROR: {
        unknownCause: "This was an unexpected error",
        reload: "Please try reloading/clearing your cache first",
        report: "If the problem persists, please report it"
    },
    GOCRYPTO: "",
    TOOLTIPS: {
        PAYMENT: {
            mobile: {
                0: "Use the keyboard to enter the payment amount.",
                1: "Press Continue to payment. This opens the screen for scanning the QR code."
            },
            tablet: {
                0: "Use the keyboard to enter the payment amount.",
                1: "Press Continue to payment. This opens the screen for scanning the QR code."
            },
            desktop: {
                0: "Use the keyboard to enter the payment amount.",
                1: "Press Continue to payment. This opens the screen for scanning the QR code."
            }
        },
        ARCHIVE: {
            mobile: {
                0: "View payments according to their time and status.",
                1: "Export payments according to their time and success in PDF or CSV format."
            },
            tablet: {
                0: "View payments according to their time and status.",
                1: "Export payments according to their time and success in PDF or CSV format."
            },
            desktop: {
                0: "View payments according to their time and status.",
                1: "Export payments according to their time and success in PDF or CSV format."
            }
        },
        next: "NEXT"
    },
    FAQ: {
        title: "webPOS - frequently asked questions",
        QA: [
            {
                question: "How can I create the GoCrypto payment?",
                answer: <Fragment>
                    <p>Select “New Payment” in the main menu and enter payment amount in dedicated entry field using keyboard or touch screen. A reference number can be added optionally.</p>
                    <p>Click “Create payment” and a QR code will be generated. Ask buyer to scan this QR code or the sticker, which was placed in front of your cashier desk only if the screen is not easily accessible.</p>
                    <p>Once buyer completes the process, the green arrow will appear with status “Payment successful”. This indicates that funds are received, and you can issue an invoice. </p>
                    <p>For your accounting needs you can enter the “Archives” and print out the confirmation slip. </p>
                </Fragment>
            },
            {
                question: "Payment expired. What does it mean?",
                answer: <Fragment>
                    <p>When payment is created, the QR code with a timer appears. From this point onwards, the user needs to complete the payment in 5 minutes. If it takes longer, the process expires and you should create payment once again. </p>
                </Fragment>
            },
            {
                question: "I entered wrong payment amount and QR code is already generated. What now?",
                answer: <Fragment>
                    <p>No worries, simply click on the button “Cancel payment” and repeat the process once again. </p>
                </Fragment>
            },
            {
                question: "Where can I review the history of payments and make an export?",
                answer: <Fragment>
                    <p>Click Archives in the main menu to scroll through complete history of payments. You can filter the results showed by payment status and date. For you accounting needs the Archives can be exported in CSV or PDF form.</p>
                </Fragment>
            },
            {
                question: "Can I generate the daily Statement of accounts?",
                answer: <Fragment>
                    <p>Of course, click Billing in the main menu where you can print out the daily statement, where payments made with individual currencies are merged. </p>
                </Fragment>
            },
            {
                question: "Which cryptocurrencies are supported?",
                answer: <Fragment>
                    <p>GoCrypto enables payments with Bitcoin, Bitcoin Cash, Ether, the GoC token, Litecoin, Tezos and the Viberate token. But you shall not worry about this. Once user scans the QR code, they will select payment currency inside their application, so for you the process remains the same in all cases. </p>
                    <p>In addition to cryptocurrencies in some regions GoCrypto supports also payments with pre-deposited Euro value. </p>
                </Fragment>
            },
            {
                question: "Which wallets buyers can use to complete the payment?",
                answer: <Fragment>
                    <p>Buyers can use Elly or Bitcoin.com application. Your process is completely the same, no matter which wallet the user will choose.  </p>
                </Fragment>
            },
            {
                question: "Is payment with regular currency supported?",
                answer: <Fragment>
                    <p>Yes, in addition to cryptocurrencies in some regions GoCrypto supports also payments with pre-deposited Euro value.</p>
                </Fragment>
            },
            {
                question: "My buyer is asking for a reimbursement.",
                answer: <Fragment>
                    <p>Once payment is successfully completed, the funds cannot be reimbursed through the GoCrypto system and the refund must be done by merchant.</p>
                </Fragment>
            },
            {
                question: "I have issues creating a payment or using GoCrypto POS.",
                answer: <Fragment>
                    <p>You can send an email to <a href="mailto:support@gocrypto.com">support@gocrypto.com</a>. </p>
                </Fragment>
            }
        ]

    },
};
