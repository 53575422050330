
import {apiClient} from './util';
import {AxiosPromise} from 'axios';


export default {
    getSupportedLanguageList: (): AxiosPromise => apiClient.get("/languages"),
    setPOSLanguage: (langCode): AxiosPromise =>  {
        const requestBody = {
            language: langCode
        };

        return apiClient.post("/language/pos", requestBody);
    }
};
